<template>
	<!-- 分销商提现列表 -->
	<div class="storeWithdraw">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<a-space style="margin-bottom: 12px;">
				<span class="f13">提现状态：</span>
				<a-radio-group v-model:value="search.status" button-style="solid" @change="getDistrbution(1,info.limit)">
					<a-radio-button :value="1 ">待审核</a-radio-button>
					<a-radio-button :value="2 ">已通过</a-radio-button>
					<a-radio-button :value="3 ">未通过</a-radio-button>
				</a-radio-group>
			</a-space>
			<br>
			<a-space>
				<span class="f13">查询参数：</span>
				<a-input v-model="search.name" placeholder="姓名" style="width: 240px;" />
				<a-input-search 
					style="width: 320px;" 
					v-model="search.account" 
					placeholder="账号" 
					enter-button 
					@search="getDistrbution(1,info.limit)" 
				/>
			</a-space>
		</div>
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table row-key="id" :pagination="false" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'用户信息',dataIndex:'name',slots:{customRender:'name'}},
				{title:'提现金额',dataIndex:'price',slots:{customRender:'price'}},
				{title:'状态',dataIndex:'withdraw_status',slots:{customRender:'withdraw_status'}},
				{title:'提现方式',dataIndex:'method',slots:{customRender:'method'}},
				{title:'备注',dataIndex:'remark'},
				{title:'操作时间',dataIndex:'create_time',slots:{customRender:'create_time'}},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]">
			    <template #name="{record}">
					<div v-if="record.account" style="display: flex;align-items: center;" class="f14">
						<a-avatar :size="48" :src="record.account.avatar_url" />
						<div style="margin-left: 12px;">
							<div>{{ record.account.nickname }}</div>
							<div><span class="f12">UID：</span>{{record.account_id}} </div>
							<div><span class="f12">姓名：</span>{{record.name}} </div>
							<div><span class="f12">打款账号：</span>{{record.account_show}} </div>
						</div>
					</div>
					<div v-else>
						<div>UID:{{record.account_id}} </div>
						<div>姓名:{{record.name}} </div>
						<div>打款账号:{{record.account_show}} </div>
					</div>
				</template>
			   
				<template #price="{record}">
					<div><span class="f12">金额：</span> <span style="color: red;">{{ record.price }} </span>元</div>
					<div><span class="f12">手续费：</span><span style="color:#FF9900">{{ record.handingfee}}</span>元 </div>
				</template>
			   
				<template #withdraw_status="{record}">
					<a-tag :color="statusShow(record.status,2)" >{{statusShow(record.status,1)}}</a-tag>
				</template>
			   
				<template #method="{record}">
					<a-tag :color="record.type == 1 ?'green':'blue'">{{ record.type == 1 ?'微信':'支付宝'}}</a-tag>
				</template>
				<template #create_time="{record}">
					<div><span class="f12">申请时间：</span>{{ record.create_time }}</div>
					<div><span class="f12">操作时间：</span>{{ record.update_time || '--'}}</div>
				</template>
			   
				<template #action="{record}">
					<div v-if="record.status==1" style="cursor: pointer;">
						<a-tag v-has="{action:'wxapp_account_withdraw_agree'}" @click="changeWithdraw(record.id,2)" color="#108ee9">通过审核</a-tag>
						<a-tag v-has="{action:'wxapp_account_withdraw_agree'}" @click="changeWithdraw(record.id,4)" color="#FF0066">手动打款</a-tag>
						<a-tag v-has="{action:'wxapp_account_withdraw_deny' }" @click="changeWithdraw(record.id,3)" color="#FF9900">驳回审核</a-tag>
					</div>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getDistrbution(info.page,e)}"
					@change="(e)=>{getDistrbution(e,info.limit)}"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import distributionModel from '@/api/addons/distribution'
export default{
	computed:{
		statusShow(){
			return function(e,type){
				return type == 1 ? ( e ==1 ?'待审核':(e==2?'已提现':'已拒绝')) :(e==1?'#999':(e==2?'#00CC66':'#f50'))
			}
		}
	},
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			},
			search:{name:'',account:'',status:1}
		})
		getDistrbution(1,state.info.limit)
		
		function getDistrbution(page,limit){
			distributionModel.getDisWithdraw(page,limit,state.search,res=>state.info = {limit,...res})
		}

		const changeWithdraw = (id,status)=>distributionModel.handleDisWithdraw(
			id,status,()=>getDistrbution(state.info.page,state.info.limit)
		)

		return{
			...toRefs(state),
			changeWithdraw,
			getDistrbution
		}
	}
}
</script>

<style>
</style>
